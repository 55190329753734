<template>
  <v-container>
    <thumbnail-challenge-component />
  </v-container>
</template>

<script>
import ThumbnailChallengeComponent from "../components/ThumbnailChallengeComponent";

export default {
  name: "ChallengesView",
  components: {
    ThumbnailChallengeComponent,
  },
};
</script>
